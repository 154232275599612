const Style = ({ attributes, clientId, isBackend = false }) => {
	const { columnGap, rowGap, background, textAlign, itemHeight, itemPadding, itemShadow, itemBorder, iconPadding, iconMargin, titleTypo, titleMargin, descTypo } = attributes;

	const gapStyle = isBackend ? `#ssbServices-${clientId} .ssbServices .block-editor-inner-blocks .block-editor-block-list__layout{
		grid-gap: ${rowGap} ${columnGap};
	}` : `#ssbServices-${clientId} .ssbServices{
		grid-gap: ${rowGap} ${columnGap};
	}`

	return <style dangerouslySetInnerHTML={{
		__html: `
		${titleTypo?.googleFontLink ? `@import url(${titleTypo?.googleFontLink});` : ''}
		${descTypo?.googleFontLink ? `@import url(${descTypo?.googleFontLink});` : ''}

		#ssbServices-${clientId} .ssbServices{
			${background?.styles || 'background-color: #0000;'}
		}
		${gapStyle}

		#ssbServices-${clientId} .ssbServices .ssbService{
			text-align: ${textAlign};
			min-height: ${itemHeight};
			padding: ${itemPadding?.styles || '50px 30px 50px 50px'};
			box-shadow: ${itemShadow?.styles || '0 0 20px 0 #0000001a'};
			${itemBorder?.styles || 'border-radius: 15px;'}
		}
		#ssbServices-${clientId} .ssbServices .ssbService .bgLayer{
			border-radius: ${itemBorder?.radius || '15px'}
		}
		#ssbServices-${clientId} .ssbServices .ssbService .icon{
			padding: ${iconPadding?.styles || '0 0 10px 0'};
			margin: ${iconMargin?.styles || '0 0 20px 0'};
		}
		#ssbServices-${clientId} .ssbServices .ssbService .title{
			${titleTypo?.styles || 'font-size: 23px;'}
			margin: ${titleMargin?.styles || '0 0 30px 0'};
		}
		#ssbServices-${clientId} .ssbServices .ssbService .description{
			${descTypo?.styles || 'font-size: 16px;'}
		}
		`.replace(/\s+/g, ' ')
	}} />
}
export default Style;