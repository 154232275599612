import servicePattern from '../img/service-pattern.png';

const Style = ({ attributes, clientId }) => {
	const { background, hoverBG, icon, iconWidth, iconBorder, titleColor, descColor, linkIconColor, linkBGColor, linkBGHovColor } = attributes;

	return <style dangerouslySetInnerHTML={{
		__html: `
		#ssbService-${clientId} .ssbService{
			${background?.styles || `background-color: #0000;`}
		}
		#ssbService-${clientId} .ssbService .bgLayer{
			${hoverBG?.styles || `background-image: url(${servicePattern}); background-position: bottom left; background-size: auto; background-repeat: no-repeat; background-color: #0000;`}
		}

		#ssbService-${clientId} .ssbService .title{
			color: ${titleColor};
		}
		#ssbService-${clientId} .ssbService .icon{
			${iconBorder?.styles || ''}
		}
		#ssbService-${clientId} .ssbService .icon i{
			${icon?.styles || 'font-size: 70px;'}
		}
		#ssbService-${clientId} .ssbService .icon img{
			width: ${iconWidth};
		}
		#ssbService-${clientId} .ssbService .description{
			color: ${descColor};
		}
		#ssbService-${clientId} .ssbService .link a{
			background-color: ${linkBGColor};
		}
		#ssbService-${clientId}:hover .ssbService .link a{
			background-color: ${linkBGHovColor};
		}
		#ssbService-${clientId} .ssbService .link a svg{
			fill: ${linkIconColor};
		}
		`.replace(/\s+/g, ' ')
	}} />
}
export default Style;