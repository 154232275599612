import { render } from '@wordpress/element';

import './child/script';
import './style.scss';
import Style from './Style';
// import Service from './Service';

// // Services
document.addEventListener('DOMContentLoaded', () => {
	const allServices = document.querySelectorAll('.wp-block-services-section-services');
	allServices.forEach(services => {
		const attributes = JSON.parse(services.dataset.attributes);

		const ssbServicesStyle = document.querySelector(`#${services.id} .ssbServicesStyle`);

		render(<Style attributes={attributes} clientId={attributes.cId} />, ssbServicesStyle);

		services?.removeAttribute('data-attributes');
	});
});